import * as React from "react";
import Container from "../layout/container";
import Button from "../components/common/Button";
const PageNotFound = () => {
  return (
    <Container>
      <section className="flex flex-col justify-center items-center gap-6 w-full px-8 min-h-[50vh] pt-20 pb-24 ">
        <h1 className="dsc-h1 leading-[46px] text-center font-bold">
          ページが見つかりません
        </h1>
        <div className="text-center">
          <p className="leading-7 text-[16px]">
            指定されたURLのページは存在しません。
          </p>
          <p className="leading-7 text-[16px]">
            サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
          </p>
        </div>
        <a href="/">
          <Button>
            <span className="leading-6">ホームページに戻る</span>
          </Button>
        </a>
      </section>
    </Container>
  );
};

export default PageNotFound;
